import * as Sentry from '@sentry/react';
import { isLocalhost } from '_core';
import * as coreEntities from '_core/store/index';
import { ROUTES } from 'app/utils/constants/paths';
import { getLemonadeTranslates } from 'app/utils/lemonade-translates';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useMintLessonEditorPlusV1Lib, useMintLessonVisorPlusV1Lib } from 'react-mint-editor';
import { useSelector } from 'react-redux';
import { matchPath } from 'react-router-dom';

const routes = Object.values(ROUTES)
  .map((route) => ({
    path: route,
  }))
  .sort((a, b) => b.path.length - a.path.length);

const InitsContainer = ({ history }) => {
  const currentUser = useSelector((state) => coreEntities.auth.selectors.getUser(state));
  const tenant = useSelector((state) => coreEntities.organization.selectors.getTenant(state));
  const language = useSelector((state) => coreEntities.i18n.selectors.getLanguage(state));
  const { api: apiEditor } = useMintLessonEditorPlusV1Lib();
  const { api: apiVisor } = useMintLessonVisorPlusV1Lib();

  useEffect(() => {
    if (language === 'pt') require('moment/locale/pt.js');
    else if (language === 'en') moment.locale('en');
    else require('moment/locale/es.js');
  }, [language]);

  useEffect(() => {
    if (tenant && !isLocalhost()) {
      Sentry.init({
        dsn: 'https://c583e7250991f783148988b55836f209@o4505759437029376.ingest.us.sentry.io/4506257572626432',
        environment: tenant?.name,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration(),
          // Sentry.replayCanvasIntegration()
          Sentry.thirdPartyErrorFilterIntegration({
            // Specify the application keys that you specified in the Sentry bundler plugin
            filterKeys: ['tangerine-frontoffice'],

            // Defines how to handle errors that contain third party stack frames.
            // Possible values are:
            // - 'drop-error-if-contains-third-party-frames'
            // - 'drop-error-if-exclusively-contains-third-party-frames'
            // - 'apply-tag-if-contains-third-party-frames'
            // - 'apply-tag-if-exclusively-contains-third-party-frames'
            behaviour: 'drop-error-if-contains-third-party-frames',
          }),
          Sentry.reactRouterV5BrowserTracingIntegration({
            history,
            routes,
            matchPath,
          }),
        ],

        // Performance Monitoring
        tracesSampleRate: 0.1, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
        // Session Replay
        replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        beforeSend(event, hint) {
          // Accede al mensaje de error original
          const errorMessage = hint?.originalException?.message;

          // Comprueba si el mensaje de error es el que quieres ignorar
          if (errorMessage?.includes('cannot be parsed as a URL') || errorMessage?.includes('Failed to fetch') || errorMessage?.includes('$(iframe).attr')) {
            // No enviar el evento a Sentry
            return null;
          }

          // Devuelve el evento si no coincide con el filtro
          return event;
        },
      });
    }
  }, [tenant]);

  useEffect(() => {
    if (currentUser && !isLocalhost()) {
      Sentry.setUser({
        id: currentUser.guid,
        username: currentUser.username,
        name: `${currentUser.name} ${currentUser.lastname}`,
        role: currentUser.role_name,
      });
    } else if (!currentUser && !isLocalhost()) {
      // Clear user data when user logs out
      Sentry.setUser(null);
    }
  }, [currentUser]);

  useEffect(() => {
    getLemonadeTranslates(apiEditor, apiVisor);
  }, []);

  return <></>;
};

InitsContainer.propTypes = {
  history: PropTypes.object,
};

export default InitsContainer;
